.root
  padding: 0 0 var(--mantine-spacing-md) 0

.timelineHeaders
  position: sticky
  top: 70px
  z-index: 99
  > div
    border: 0 !important

.isoWeek
  height: 20px !important
  > div
    cursor: auto !important
  > div > span
    color: var(--mantine-primary-color-3)
    font-size: var(--mantine-font-size-xs)
    text-transform: capitalize
    cursor: auto !important