.name
  color: var(--mantine-color-hifivework-3)

.icon
  height: 50px
  width: 50px
  color: var(--mantine-color-hifivework-5)

.wrapper
  border: 0.5px solid lightgrey
  border-radius: 10px
  padding: var(--mantine-spacing-xs)
  background-color: var(--mantine-color-hifivework-0)