.scheduler
  $scheduler-height: 600px
  $scheduler-header-col-width: 200px
  $scheduler-header-row-items-count: 31
  $cell-width: 117px
  $cell-height: 42px
  $left-column-width: 60px
  --scheduler-cell-width: #{$cell-width}
  --scheduler-cell-min-width: #{$cell-width}
  --scheduler-row-items-count: #{$scheduler-header-row-items-count}
  --scheduler-cell-height: #{$cell-height}
  --scheduler-height: #{$scheduler-height}
  --scheduler-left-column-width: #{$left-column-width}
  --table-border-color: var(--mantine-color-gray-3)

  height: var(--scheduler-height)
  width: 100%
  overflow: hidden
  background-color: white

  &-header
    display: grid
    grid-template-columns: 2px

    &-row
      display: grid
      grid-template-columns: var(--scheduler-left-column-width) auto

      &-workInfo
        height: $cell-height * 2

      &-empty-cell
        width: var(--scheduler-left-column-width)
        border-bottom: solid 1px var(--table-border-color)
        border-right: solid 1px var(--table-border-color)
        flex-shrink: 0
        background-color: white
        z-index: 5

      &-scrollable-cells
        overflow: hidden
        width: calc(var(--scheduler-cell-width) * var(--scheduler-row-items-count))
        display: grid
        grid-template-columns: repeat(var(--scheduler-row-items-count), minmax(var(--scheduler-cell-width), 1fr))
        height: $cell-height

        &-workInfo
          height: $cell-height * 2

        &-cell
          display: flex
          width: var(--scheduler-cell-width)
          height: $cell-height
          border-right: solid 1px var(--table-border-color)
          border-bottom: solid 1px var(--table-border-color)
          align-items: center
          text-align: center
          justify-content: center

          &-workInfo
            display: block
            height: $cell-height * 2

  &-content
    display: flex

    &-row
      display: grid
      width: 100%
      grid-template-columns: 60px auto

      &-left-column
        width: var(--scheduler-left-column-width)
        border-bottom: solid 1px var(--table-border-color)
        border-right: solid 1px var(--table-border-color)
        flex-shrink: 0
        background-color: white
        z-index: 4
        height: 100%

        &-cell-hour
          transform: translate(0px, 33px)
          z-index: 4
          background-color: white
          width: 50px
          text-align: right
          padding-right: 5px

      &-scrollable
        width: 100%
        height: calc(var(--scheduler-height) - $cell-height * 3)
        overflow: scroll
        display: block

        &-cells
          width: calc(var(--scheduler-cell-width) * var(--scheduler-row-items-count))
          display: grid
          grid-template-columns: repeat(var(--scheduler-row-items-count), minmax(var(--scheduler-cell-width), 1fr))
          height: 100%
          position: relative

      &-cell
        display: flex
        width: var(--scheduler-cell-width)
        height: $cell-height
        border-right: solid 1px var(--table-border-color)
        border-bottom: solid 1px var(--table-border-color)
        flex-shrink: 0

        &-add-event
          display: none
          background-color: #f9f9f9
          width: 100%
          height: 100%

          button
            display: flex
            margin: 0 auto

        &:hover &-add-event
          display: block
          cursor: pointer

      &-left-column &-cell
        width: 60px

  &-events-day-column
    width: var(--scheduler-cell-width)
    height: 100%

  &-event-cell
    position: absolute
    top: 100px
    width: calc(var(--scheduler-cell-width) - 1)
    height: 200px
    border: 1px solid rgba(0,0,0, 0.25)
    padding: 4px
    margin: 0 1px
    border-radius: 4px

    &-delete
      position: absolute
      width: calc($cell-height / 4)
      height: calc($cell-height / 4)
      right: 2px
      top: 2px
      cursor: pointer

    &-resizable-handle-top,&-resizable-handle-bottom
      position: absolute
      height: 20px
      width: 100%
      left: 0px
      svg
        display: none
    &-resizable-handle-top
      top: -10px
      cursor: s-resize
    &-resizable-handle-bottom
      bottom: -10px
      cursor: n-resize

    &:hover &-resizable-handle-bottom, &:hover &-resizable-handle-top
      display: flex
      justify-content: center
      svg
        display: block
    &:hover &-resizable-handle-top
      svg
        transform: translateY(4px)
    &:hover &-resizable-handle-bottom
      svg
        transform: translateY(-1px)

  /* for dropdown status input on every day */
  &-status
    &-input
      height: 24px
      line-height: 0.2
      max-height: 24px
    &-root
      height: 26px
      width: 95%
      margin: 2px auto
    &-wrapper
      height: 28px
    &-dropdown
      padding: 2px
    &-option
      padding: 2px

.input
  height: auto !important
  font-size: var(--mantine-font-size-sm) !important
  padding: 2px
  min-height: unset
  line-height: unset
  min-width: 28px