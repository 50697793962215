.root
  display: flex
  align-items: center
  gap: 8px

.input
  height: auto !important
  font-size: var(--mantine-font-size-sm) !important
  padding: 2px
  min-height: unset
  line-height: unset
  min-width: 28px