.icon
  color: var(--mantine-color-hifivework-6)

.iconError
  color: var(--mantine-color-red-6)

.iconSucces
  color: var(--mantine-color-green-6)

.iconCheck
  color: var(--mantine-color-hifivework-6)