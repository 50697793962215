@import "../_mantine.scss"

body, p, span, a, button, td
  font-family: $body-font

body
  background-color: $background-color

h1, h2, h3, h4, h5, h6
  font-family: $heading-font

#reactSchedulerOutsideWrapper
  position: relative

.hUDhvK, .bwVOYM, .fOgBaC:first-child::after
  display: none!important

.cgvzyy
  border: 0.5px solid lightgray!important