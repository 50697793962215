.root
  background-color: #fafafa
  padding-bottom: 16px
  width: fit-content

.iframe
  width: inherit
  height: inherit
  min-height: 90vh
  max-height: 90vh
  border: 0

.flagSelect
  padding: 0

  > button
    border: none
    background: none
    padding: 0

    &::after
      display: none

    > span
      padding: 0