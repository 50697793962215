.value
  font-size: 36px
  font-weight: 700
  line-height: 1
  color: var(--mantine-color-hifivework-6)

.icon
  color: var(--mantine-color-hifivework-1)

.title
  font-weight: 700
  text-transform: uppercase
