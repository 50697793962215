.user
  display: block
  width: 100%
  padding: var(--mantine-spacing-xs)
  &:hover
    background-color: var(--mantine-color-hifivework-0)
    border-radius: var(--mantine-spacing-sm)

.box
  padding: var(--mantine-spacing-sm)

.item
  font-size: var(--mantine-font-size-sm)
  color: #707070
  font-weight: bold
  &:hover
    background-color: var(--mantine-color-gray-0)
    border-radius: var(--mantine-spacing-sm)
    color: var(--mantine-color-hifivework-5)


.text
  overflow: hidden
  text-overflow: ellipsis