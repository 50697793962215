.root
  padding-top: 80px
  padding-bottom: 80px

.label
  text-align: center
  font-weight: 900
  font-size: 220px
  margin-bottom: var(--mantine-spacing-xl)
  color: var(--mantine-color-gray-2)

  @media (min-width: 768px)
    font-size: 120px

.title
  text-align: center
  font-weight: 900
  font-size: 38px

  @media (min-width: 768px)
    font-size: 32px

.description
  max-width: 500px
  margin: var(--mantine-spacing-xl) auto
  align-content: center
  text-align: justify