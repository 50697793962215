.navbar
    height: 100%
    width: 100%
    padding: var(--mantine-spacing-md)
    padding-bottom: 0
    display: flex
    flex-direction: column

.links 
    flex: 1
    margin-left: calc(var(--mantine-spacing-md) * -1)
    margin-right: calc(var(--mantine-spacing-md) * -1)

.linksInner
    padding-top: var(--mantine-spacing-xs)
    padding-bottom: var(--mantine-spacing-xl)
