.dashboardRoot
  border: 0.5px solid lightgrey
  border-radius: 10px
  padding: var(--mantine-spacing-xs)
  background-color: var(--mantine-color-hifivework-0)

.capitalize:first-letter
  text-transform: capitalize

.input
  height: auto !important
  font-size: var(--mantine-font-size-xs) !important
  padding: 2px
  min-height: unset
  line-height: unset
  min-width: 28px

.hasOvertimeInput
  color: red
  font-weight: bold

.unsetMinWidth
  padding: revert-layer