.icon
  color: #707070
  &:hover
    color: var(--mantine-color-hifivework-5)

.control
  font-weight: 500
  display: block
  width: 100%
  margin: 2px 0 2px 0
  padding: var(--mantine-spacing-xs)
  font-size: var(--mantine-font-size-sm)
  border-radius: var(--mantine-radius-lg)
  color: #707070
  &[data-active]
    background-color: var(--mantine-color-hifivework-0)
    color: var(--mantine-hifivework-5)
  &:hover
    background-color: var(--mantine-color-hifivework-0)
    border-radius: var(--mantine-radius-lg)
    color: var(--mantine-color-hifivework-5)

.link
  width: 80%
  font-weight: 500
  display: block
  text-decoration: none
  padding: var(--mantine-spacing-xs) var(--mantine-spacing-md)
  margin-left: var(--mantine-spacing-lg)
  font-size: var(--mantine-font-size-sm)
  color: #707070
  border-left: 1px solid var(--mantine-color-gray-3)
  cursor: pointer

  &:hover, &[data-active]
    background-color: var(--mantine-color-hifivework-0)
    border-radius: 0 var(--mantine-spacing-sm) var(--mantine-spacing-sm) 0
    color: var(--mantine-color-hifivework-5)


.chevron
  transition: transform 200ms ease