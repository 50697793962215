.react-calendar-timeline
    *
        box-sizing: border-box

    .rct-outer
        display: block
        overflow: hidden
        white-space: nowrap

    .rct-scroll
        display: inline-block
        white-space: normal
        vertical-align: top
        overflow-x: scroll
        overflow-y: hidden
        -ms-touch-action: none
        touch-action: none

    .rct-item
        &:hover
            z-index: 88
            box-shadow: rgba(50, 50, 93, 0.25) 0 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset

        .rct-item-content
            position: sticky
            position: -webkit-sticky
            left: 0
            overflow: hidden
            display: inline-block
            border-radius: 2px
            padding: 0 6px
            height: 100%

    .rct-sidebar
        overflow: hidden
        white-space: normal
        display: inline-block
        vertical-align: top
        position: relative
        box-sizing: border-box
        border-right: 1px solid #e9ecef

        &.rct-sidebar-right
            border-right: 0
            border-left: 1px solid #e9ecef

        .rct-sidebar-row
            padding: 0 4px
            overflow: hidden
            white-space: nowrap
            text-overflow: ellipsis
            box-sizing: border-box
            margin: 0
            cursor: pointer

            &.rct-sidebar-row-odd
                background: #f8f9fa
                &:hover
                    background: #e9ecef

            &.rct-sidebar-row-even
                background: #FFFFFF
                &:hover
                    background: #e9ecef

    .rct-vertical-lines .rct-vl
        position: absolute
        z-index: 30

        &.rct-day-6, &.rct-day-0
            background: rgba(250, 246, 225, 0.3)

    .rct-horizontal-lines
        -webkit-user-select: none
        -moz-user-select: -moz-none
        -ms-user-select: none
        user-select: none

        .rct-hl-even
            box-sizing: border-box
            z-index: 40
            background: transparent
            cursor: crosshair
            &:hover
                background: #e9ecef

        .rct-hl-odd
            box-sizing: border-box
            z-index: 40
            background: #f8f9fa
            cursor: crosshair
            &:hover
                background: #e9ecef

    .rct-cursor-line
        position: absolute
        width: 2px
        background: #2196f3
        z-index: 51

    .rct-dateHeader
        display: flex
        align-items: center
        justify-content: center
        height: 100%
        background-color: #f8f9fa
        text-transform: capitalize
        font-size: 1.25rem
        font-weight: bold
        &:not(:first-child)
            border-left: 1px solid #adb5bd

    .rct-dateHeader-primary
        background-color: #FFFFFF
        color: #647fcc

    .rct-header-root
        background: #FFFFFF

    .rct-calendar-header
        border: 1px solid #e9ecef
