.flipCard
	background-color: transparent
	height: 230px
	perspective: 1000px

.flipCardInner
	position: relative
	width: 100%
	height: 100%
	text-align: center
	transition: transform 0.8s
	transform-style: preserve-3d
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2)

.flipCard:hover .flipCardInner
	transform: rotateY(180deg)

.flipCardFront, .flipCardBack
	position: absolute
	width: 100%
	height: 100%
	-webkit-backface-visibility: hidden
	backface-visibility: hidden
	transform: rotateX(0deg)

.flipCardBack
	color: white
	transform: rotateY(180deg)
	padding: 16px