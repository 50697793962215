@use 'src/styles/global' as *

.paymentAccount
  margin-bottom: var(--mantine-spacing-md)

.card
  background-color: var(--mantine-color-gray-0)

.overviewWrapper
  background-color: var(--mantine-color-body)
  border-radius: var(--mantine-radius-md)
  border-width: 1px
  border-style: solid
  border-color: var(--mantine-primary-color-light)
  min-height: 200px
  height: 100%
  width: 100%
  padding: var(--mantine-spacing-md)
  @include scale-on-hover

.flexItem
  display: flex
  gap: 4px
  align-items: center
  width: auto
  text-overflow: ellipsis

.fullwidth
  width: 100%

.blockquoteCite
  margin: unset