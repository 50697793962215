.folderOrFileText
  cursor: pointer
  color: var(--mantine-color-hifivework-5)
  &:hover
    text-decoration: underline

.paper
  background-color: var(--mantine-color-hifivework-0)

.icon
  color: var(--mantine-color-hifivework-5)