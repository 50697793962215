.tabPanel
  border-left: 1px solid var(--_tab-border-color)
  border-right: 1px solid var(--_tab-border-color)
  border-bottom: 1px solid var(--_tab-border-color)
  border-bottom-left-radius: 5px
  border-bottom-right-radius: 5px

.chipLabel
  background-color: transparent
  &:hover
    filter: brightness(85%)