.timelineDailyWorkInfo
  $timeline-header-col-width: 200px
  $timeline-header-row-items-count: 31
  $cell-width: 117px
  $cell-height: 65px
  --timeline-cell-width: #{$cell-width}
  --timeline-header-row-items-count: #{$timeline-header-row-items-count}
  --table-border-color: var(--mantine-color-gray-3)

  height: 100%
  width: 100%
  overflow-x: scroll
  overflow-y: hidden
  background-color: white

  &-header
    display: grid
    grid-template-columns: 2px

    &-row
      display: grid
      grid-template-columns: repeat(var(--timeline-header-row-items-count), minmax(var(--timeline-cell-width), 1fr))
      width: 100%

      &-cell
        display: flex
        justify-content: center
        text-align: center
        border-left: solid 1px var(--table-border-color)
        border-bottom: solid 1px var(--table-border-color)

  &-content
    height: 100%
    width: 100%
    display: grid
    grid-template-columns: var(--timeline-cell-width)

    &-row
      display: grid
      grid-template-columns: repeat(var(--timeline-header-row-items-count), minmax(var(--timeline-cell-width), 1fr))
      height: $cell-height