.navbar
  display: flex
  flex-direction: column
  height: 100%

.wrapper
  display: flex
  flex: 1

.aside
  box-shadow: 0 6px 6px #00000029
  background-color: var(--mantine-color-body)
  display: flex
  flex-direction: column
  align-items: center
  gap: 4px
  padding-top: var(--mantine-spacing-xs)

.main
  flex: 1
  overflow: hidden

.mainLink
  border-radius: var(--mantine-radius-md)
  display: flex
  align-items: center
  justify-content: center
  color: var(--mantine-color-gray-7)
  &:hover
    background-color: var(--mantine-color-hifivework-0)
  &[data-active]
    background-color: var(--mantine-color-hifivework-0)
    color: var(--mantine-color-hifivework-5)

.title
  background-color: var(--mantine-color-body)
  overflow: hidden
  text-overflow: ellipsis

.link
  box-sizing: border-box
  display: block
  text-decoration: none
  border-top-right-radius: var(--mantine-radius-md)
  border-bottom-right-radius: var(--mantine-radius-md)
  color: #707070
  padding: 0 var(--mantine-spacing-md)
  font-size: var(--mantine-font-size-sm)
  margin: 6px var(--mantine-spacing-md) 6px 0
  &:hover
    background-color: var(--mantine-color-hifivework-0)
    color: var(--mantine-color-hifivework-5)
  &[data-active]
    background-color: var(--mantine-color-hifivework-0)
    color: var(--mantine-color-hifivework-5)