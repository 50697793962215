@use 'sass:math';

@function light-dark($light, $dark) {
  @if var(--mantine-color-scheme) == 'light' {
    @return $light;
  }
  @return $dark
}

// Define variables for your breakpoints,
// values must be the same as in your theme
$mantine-breakpoint-xs: '36em';
$mantine-breakpoint-sm: '48em';
$mantine-breakpoint-md: '62em';
$mantine-breakpoint-lg: '75em';
$mantine-breakpoint-xl: '88em';
$body-font: 'Manrope', apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif !default;
$heading-font: 'Arboria', apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif !default;
$background-color: light-dark(#F8F9FA, transparent);


@function rem($value) {
  @return #{math.div(math.div($value, $value * 0 + 1), 16)}rem;
}

@mixin light {
  [data-mantine-color-scheme='light'] & {
    @content;
  }
}

@mixin dark {
  [data-mantine-color-scheme='dark'] & {
    @content;
  }
}

@mixin hover {
  @media (hover: hover) {
    &:hover {
      @content;
    }
  }

  @media (hover: none) {
    &:active {
      @content;
    }
  }
}

@mixin smaller-than($breakpoint) {
  @media (max-width: $breakpoint) {
    @content;
  }
}

@mixin larger-than($breakpoint) {
  @media (min-width: $breakpoint) {
    @content;
  }
}

@mixin scale-on-hover {
  transition:
    box-shadow 150ms ease,
    transform 100ms ease;

  &:hover {
    box-shadow: var(--mantine-shadow-md);
    transform: scale(1.02);
  }
}

@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  src: url(https://example.com/Manrope.eot); /* For IE6-8 */
  src: local('Manrope'),
  local('Manrope'),
  url('/fonts/Manrope.ttf') format('truetype');
}

@font-face {
  font-family: 'Arboria';
  font-style: normal;
  font-weight: 400;
  src: url(https://example.com/Arboria.eot); /* For IE6-8 */
  src: local('Arboria'),
  local('Arboria'),
  url('/fonts/Arboria.ttf') format('truetype');
}