.root
  padding: 16px

.title
  font-weight: 700
  text-transform: uppercase

.value
  line-height: 1px
  color: var(--mantine-color-hifivework-5)

