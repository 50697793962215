.root
  background-color: #fafafa
  padding-bottom: 16px
  width: 100%

.iframe
  width: inherit
  height: inherit
  min-height: calc(100vh - 200px)
  max-height: calc(100vh - 200px)
  border: 0