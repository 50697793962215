.icon
  color:  var(--mantine-color-hifivework-5)
  height: 28px
  width: 28px

.iconError
  color: var(--mantine-color-red-6)

.iconSucces
  color: var(--mantine-color-green-6)

.wrapper
  border: 0.5px solid lightgrey
  border-radius: 10px
  padding: var(--mantine-spacing-xs)
  background-color: var(--mantine-color-hifivework-0)

.name
  color: var(--mantine-color-hifivework-3)